import Layout from "../../hocs/Layout";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Form,
  Row,
  Container,
  ToggleButtonGroup,
  ToggleButton,
  Card,
} from "react-bootstrap";
import { TextInput, SelectInput } from "../../components/inputs/Inputs";
import DatePicker from "react-datepicker";
import { addMonths } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import {
  set_oferta_erp,
  get_datos_oferta_erp,
  copiar_ctto_actual,
  create_oferta_erp,
} from "../../services/ofertas";
import { get_tarifas } from "../../services/tarifas";
import { tarifaSchema } from "../../utils/validationSchemas";

const OfertaTarifa = ({ user }) => {
  const navigate = useNavigate();
  const params = useParams();
  const sector_type = params.sector_type;

  const tarifasOferta =
    sector_type === "electrico"
      ? [ 
          { id: 41, name: "2.0TD", key: "20" },
          { id: 42, name: "3.0TD", key: "30" },
          { id: 43, name: "6.1TD", key: "6" },
        ]
      : [
          { id: 11, name: "RL01", key: "RL01" },
          { id: 12, name: "RL02", key: "RL02" },
          { id: 13, name: "RL03", key: "RL03" },
          { id: 14, name: "RL04", key: "RL04" },
          { id: 22, name: "RLTB5", key: "RLTB5" },
        ];

  const defaultValues = {
    type_oferta: "",
    subtipo_oferta: "",
    tarifa_comercial: "",
    tarifa_oferta: "",
    mode_facturacio: "",
    coeficiente_k: 10,
    coeficiente_d: 7,
    numero_ctto: "",
    fecha_activacion: "",
    showDatePicker: null,
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    reset,
    formState: { isValid, errors },
  } = useForm({
    mode: "onBlur",
    defaultValues,
    resolver: yupResolver(tarifaSchema(sector_type)),
  });

  useEffect(() => {
    if (params.oferta_id) {
      const get_datos_iniciales = async () => {
        setOfertasErp(await get_datos_oferta_erp(params.oferta_id));
      };
      get_datos_iniciales();
    }
    // window.scrollTo(0, 0);
  }, []);

  const [ofertas_erp, setOfertasErp] = useState("");
  const [tarifas, setTarifas] = useState("");
  const [primeraCarga, setPrimeraCarga] = useState(true);
  const [loading, setLoading] = useState(false);
  const [disableTF, setDisableTF] = useState(true);

  useEffect(() => {
    if (tarifas && ofertas_erp && primeraCarga) {
      setValue(
        "tarifa_comercial",
        sector_type === "electrico"
          ? ofertas_erp.llista_preu[0]
          : ofertas_erp.llista_preu_gas[0],
        { shouldValidate: true }
      );
      setPrimeraCarga(false);
    }
  }, [tarifas]);

  useEffect(() => {
    if (ofertas_erp) {
      const elec = sector_type === "electrico";
      const fecha_base = elec
        ? ofertas_erp.fecha_estimada_inicio_ctto
        : ofertas_erp.fecha_estimada_inicio_ctto_gas;
      let fecha_activacion;
      let showDatePicker;
      if (fecha_base) {
        //const fecha_array = fecha_base.split("-");
        //fecha_activacion = new Date(...fecha_array);
        fecha_activacion = fecha_base;
        showDatePicker = "true";
      } else {
        fecha_activacion = null;
        // fecha_activacion = new Date()
        showDatePicker = "false";
      }
      const coeficienteK =
        ofertas_erp.mode_facturacio === "index"
          ? elec
            ? ofertas_erp.coeficient_k
            : ofertas_erp.gas_coeficient_k
          : defaultValues.coeficiente_k;
      reset({
        ...defaultValues,
        type_oferta: ofertas_erp.type,
        subtipo_oferta: ofertas_erp.subtipo_oferta,
        tarifa_oferta: elec
          ? ofertas_erp.tarifa_oferta[0]
          : ofertas_erp.tarifa_oferta_gas[0],
        mode_facturacio: elec
          ? ofertas_erp.mode_facturacio
          : ofertas_erp.gas_mode_facturacio,
        coeficiente_k: coeficienteK,
        fecha_activacion,
        showDatePicker,
      });
      if (elec && ofertas_erp.coeficient_d) {
        setValue("coeficiente_d", ofertas_erp.coeficient_d);
      }
      if (ofertas_erp.contrato_name) {
        setValue("numero_ctto", ofertas_erp.contrato_name);
      }
      enableTarifas();
    }
  }, [ofertas_erp]);

  const ofertasSimple =
    sector_type === "electrico"
      ? [
          "Alta nuevo suministro",
          "Cambio de titular",
          "Cambio de titular y potencia",
          "Cambio de titular y autoconsumo",
          "Cambio de titular, potencia y autoconsumo", 
          "Cambio de comercializador",
          "Cambio de comercializador y titular",
          "Cambio de comercializador y potencia",
          "Cambio de comercializador y autoconsumo",
          "Cambio de comercializador, titular y potencia",
          "Cambio de comercializador, titular y autoconsumo",
          "Cambio de comercializador, potencia y autoconsumo",
          "Cambio de comercializador, titular, potencia y autoconsumo",
        ]
      : [
          "Alta nuevo suministro",
          "Cambio de titular",
          "Cambio de comercializador",
          "Cambio de comercializador y titular",
        ];

  const ofertasRenovacion =
    sector_type === "electrico"
      ? [
          "Renovación de contrato",
          "Renovación de contrato y autoconsumo",
          "Renovación de contrato y tarifa de acceso ",
          "Renovación de contrato, autoconsumo y tarifa de acceso ",
        ]
      : [
          "Renovación de contrato",
          "Renovación de contrato y tarifa de acceso ",
        ];

  const onSearchContrato = async () => {
    const data = getValues();
    const contratoRes = await copiar_ctto_actual(
      data.numero_ctto,
      sector_type,
      user.id,
      data.type_oferta,
      data.subtipo_oferta
    );
    if (contratoRes) {
      navigate("/" + sector_type + "/tarifas/" + contratoRes["id"]);
    }
  };

  const onSubmit = async () => {
    const data = getValues();
    const tarifaComercial = tarifas.find((t) => t.id == data.tarifa_comercial);
    const elec = sector_type === "electrico";
    const fecha_activacion =
      showDatePicker === "true"
        ? data.fecha_activacion?.toISOString().split("T")[0]
        : null;
    const datos = {
      sector_type: sector_type,
      type: data.type_oferta,
      subtipo_oferta: `Subtipo: ${data.subtipo_oferta}`,
      [elec ? "llista_preu" : "llista_preu_gas"]: tarifaComercial.id,
      llista_preu_global: tarifaComercial.name,
      [elec ? "tarifa_oferta" : "tarifa_oferta_gas"]: data.tarifa_oferta,
      [elec ? "coeficient_k" : "gas_coeficient_k"]: data.coeficiente_k,
      [elec ? "mode_facturacio" : "gas_mode_facturacio"]: data.mode_facturacio,
      [elec ? "fecha_estimada_inicio_ctto" : "fecha_estimada_inicio_ctto_gas"]:
        fecha_activacion,
      mensajeFecha:
        showDatePicker === "true"
          ? fecha_activacion
          : "Cuanto antes, máximo 30 días",
      cliente_group: 2,
    };
    if (elec) {
      datos.coeficient_d = data.coeficiente_d;
    }
    try {
      if (params.oferta_id) {
        await set_oferta_erp(params.oferta_id, datos, sector_type);
        navigate(`/${sector_type}/partner/${params.oferta_id}`);
      } else {
        let oferta = "";
        oferta = await create_oferta_erp(user.id, datos);
        navigate(`/${sector_type}/partner/${oferta.id}`);
      }
    } catch (error) {}
    // window.scrollTo(0, 0);
  };

  const display_coeficiente_k = () => {
    if (modeFacturacio === "index") {
      return (
        <Col lg={6} md={12}>
          <Row className="">
            <Col md={12}>
              <Controller
                control={control}
                name="coeficiente_k"
                render={({ field: { onChange, onBlur, value } }) => (
                  <>
                    <Row>
                      <Col md="9">
                        <Form.Label className="label-color">
                          Coeficiente K en €/MWh
                        </Form.Label>
                      </Col>
                      <Col md="3">
                        <Form.Control
                          onChange={onChange}
                          value={value}
                          size="sm"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Range
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          tooltip="auto"
                          min={5}
                          max={35}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              />
            </Col>
            <span className="input-feedback">
              {errors.coeficiente_k?.message}
            </span>
          </Row>
        </Col>
      );
    }
  };

  const enableTarifas = async () => {
    const data = getValues();
    setValue("tarifa_comercial", "");
    if (
      data.tarifa_oferta &&
      data.mode_facturacio &&
      ((sector_type === "electrico" &&
        ((data.mode_facturacio === "index") ||
          data.mode_facturacio === "atr")) ||
        sector_type === "gas")
    ) {
      setLoading(true);
      const tarifaOferta = tarifasOferta.find(
        (to) => to.id == data.tarifa_oferta
      );
      const tarifasRes = await get_tarifas(
        sector_type,
        tarifaOferta.key,
        data.mode_facturacio,
        user.first_name,
      );
      setTarifas(tarifasRes);
      setLoading(false);
      setDisableTF(false);
    } else {
      setDisableTF(true);
    }
  };

  const typeOferta = watch("type_oferta");
  const modeFacturacio = watch("mode_facturacio");
  const tarifaOferta = watch("tarifa_oferta");
  const showDatePicker = watch("showDatePicker");

  return (
    <Layout lg="5" md="6" title="Tarifas">
      <Card className="card-label-corner">
        {/* <Card.Header
          className={
            sector_type === "electrico" ? "ab-primary-elec" : "ab-primary-gas"
          }
        >
          <Row className="text-center align-items-center justify-content-center my-1">
            <Col md={{ span: 9, offset: 1 }}>
              <h1 className="col-auto text-white form-label m-0">Tarifas</h1>
            </Col>
            <Col md="2">
              <img
                style={{ backgroundColor: "white", borderRadius: "50%" }}
                src={sector_type === "electrico" ? logo_electricidad : logo_gas}
                className="img-fluid p-2"
                width="40"
                title={sector_type === "electrico" ? "Electricidad" : "Gas"}
                alt={sector_type === "electrico" ? "Electricidad" : "Gas"}
              />
            </Col>
          </Row>
        </Card.Header> */}
        <Card.Body className="">
          <Row className="justify-content-center align-items-center mt-3">
            <Col>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <DevTool control={control} />
                <Row className="justify-content-start align-items-center">
                  <Col
                    md="auto"
                    className="d-flex flex-column align-items-start"
                  >
                    <label className="form-floating label-color mb-1">
                      Tipo de oferta
                    </label>
                    <Controller
                      control={control}
                      name="type_oferta"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <>
                          <ToggleButtonGroup
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            type="radio"
                            name="options"
                          >
                            <ToggleButton
                              id="simple"
                              className={
                                typeOferta === "simple"
                                  ? "toggle-selected"
                                  : "toggle-not-selected"
                              }
                              value={"simple"}
                              disabled={params.oferta_id}
                            >
                              Contrato Nuevo
                            </ToggleButton>
                            <ToggleButton
                              id="renovacion"
                              className={
                                typeOferta === "renovacion"
                                  ? "toggle-selected"
                                  : "toggle-not-selected"
                              }
                              value={"renovacion"}
                              disabled={params.oferta_id}
                            >
                              Renovación
                            </ToggleButton>
                          </ToggleButtonGroup>
                          <span className="input-feedback">
                            {errors.type_oferta?.message ?? " "}
                          </span>
                        </>
                      )}
                    />
                  </Col>
                  {!(typeOferta === "renovacion" && !params.oferta_id) && (
                    <Col lg={6} md={12} className="align-self-end">
                      <SelectInput
                        register={register}
                        name="subtipo_oferta"
                        label="Subtipo"
                        error={errors.subtipo_oferta?.message}
                        disabled={!typeOferta}
                      >
                        <option value="" key="1"></option>
                        {typeOferta === "simple"
                          ? ofertasSimple.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })
                          : ofertasRenovacion.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                      </SelectInput>
                    </Col>
                  )}
                </Row>
                {typeOferta === "renovacion" && !params.oferta_id ? (
                  <Row className="justify-content-start mb-2">
                    <Col
                      md="auto"
                      className="d-flex flex-column align-items-start"
                    >
                      <TextInput
                        register={register}
                        name="numero_ctto"
                        label="Nº Contrato"
                        error={errors.numero_ctto?.message}
                      />
                    </Col>
                    <Col md="auto" className="align-self-baseline mt-2">
                      <Button
                        className="button rounded-5"
                        onClick={onSearchContrato}
                      >
                        Buscar Contrato
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row className="justify-content-start">
                      <Col lg={4} md={12}>
                        <SelectInput
                          register={register}
                          name="mode_facturacio"
                          label="Tipo de tarifa"
                          error={errors.mode_facturacio?.message}
                          onChange={(e) => {
                            setValue("mode_facturacio", e.target.value);
                            enableTarifas();
                          }}
                        >
                          <option key="null"></option>
                          <option key="index" value="index">
                            Indexada
                          </option>
                          <option key="fijo" value="atr">
                            Fijo
                          </option>
                        </SelectInput>
                      </Col>
                      <Col lg={4} md={12}>
                        <SelectInput
                          register={register}
                          name="tarifa_oferta"
                          label="Tarifa de acceso"
                          error={errors.tarifa_oferta?.message}
                          onChange={(e) => {
                            setValue("tarifa_oferta", e.target.value);
                            enableTarifas();
                          }}
                        >
                          <option key="null" value="" disabled></option>
                          {tarifasOferta.map((to) => (
                            <option
                              key={to.id}
                              value={to.id}
                              id="tarifa_oferta"
                              name="tarifa_oferta"
                            >
                              {to.name}
                            </option>
                          ))}
                        </SelectInput>
                      </Col>
                    </Row>
                    <Row className="justify-content-start align-items-center">
                      <Col lg={loading ? 5 : 6} md={loading ? 10 : 12}>
                      <SelectInput
                          register={register}
                          type="number"
                          name="tarifa_comercial"
                          label="Tarifas comerciales"
                          error={errors.tarifa_comercial?.message}
                          disabled={disableTF || loading}
                        >
                          {loading ? (
                            <option>Cargando tarifas...</option>
                          ) : (
                            tarifas &&
                            tarifas.map((tarifa) => {
                              return (
                                <option
                                  selected={!tarifa.id}
                                  disabled={!tarifa.id}
                                  key={tarifa.id}
                                  value={tarifa.id}
                                  name="tarifa_comercial"
                                  id="tarifa_comercial"
                                >
                                  {tarifa.name}
                                </option>
                              );
                            })
                          )}
                        </SelectInput>
                      </Col>
                      {loading && (
                        <Col lg={1} md={2} className="align-self-baseline mt-3">
                          {loading && (
                            <option className="spinner-border spinner-border text-ab"></option>
                          )}
                        </Col>
                      )}
                      {display_coeficiente_k()}
                    </Row>
                    <Row className="justify-content-start align-items-center">
                      <Col md="auto">
                        <Row className="justify-content-start align-items-center">
                          <span className="label-color text-start mb-1">
                            Fecha de activación
                          </span>
                        </Row>

                        <Row className="justify-content-start align-items-center mb-1">
                          <Col>
                            <Form.Check
                              {...register("showDatePicker")}
                              value="true"
                              inline
                              label="Fecha fija"
                              name="showDatePicker"
                              type="radio"
                            />
                            <Form.Check
                              {...register("showDatePicker")}
                              value="false"
                              inline
                              label="Cuanto antes"
                              name="showDatePicker"
                              type="radio"
                            />
                          </Col>
                        </Row>
                        {showDatePicker === "true" && (
                          <Row className="justify-content-start align-items-center mb-3">
                            <Col md="auto">
                              <Controller
                                control={control}
                                name="fecha_activacion"
                                render={({
                                  field: { value, onChange, onBlur },
                                }) => (
                                  <DatePicker
                                    locale="es"
                                    dateFormat="yyyy-MM-dd"
                                    showIcon
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    selected={value}
                                    minDate={new Date()}
                                    maxDate={addMonths(new Date(), 2)}
                                    className="reborde"
                                  />
                                )}
                              />
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </>
                )}
                <Row className="justify-content-center">
                  <Col lg={8} md={12}>
                    <Row className="justify-content-evenly mx-5">
                      {(params.oferta_id || typeOferta !== "renovacion") && (
                        <Col>
                          <Button
                            className="button rounded-5 button-block mb-2"
                            type="submit"
                            disabled={!isValid}
                          >
                            Continuar
                          </Button>
                        </Col>
                      )}
                      {params.oferta_id && (
                        <Col>
                          <Button
                            className="button rounded-5 button-secondary button-block"
                            variant="secondary"
                            onClick={() => navigate("/contratos/ofertas")}
                          >
                            Volver a ofertas
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Layout>
  );
};
const mapStateToProps = (state) => ({
  user: state.Auth.user,
});
export default connect(mapStateToProps, null)(OfertaTarifa);
