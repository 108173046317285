import * as Yup from "yup";

function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === "string" && originalValue === "") {
    return null;
  }
  return value;
}

export const loginSchema = Yup.object({
  email: Yup.string().email("Ingrese un correo válido").required("Requerido"),
  password: Yup.string().required("Requerido"),
});

export const perfilSchema = Yup.object({
  email: Yup.string().email("Ingrese un correo válido"),
  first_name: Yup.string(),
  last_name: Yup.string(),
  document_id: Yup.string(),
  phone: Yup.number().typeError("Debe ser un número"),
  responsible: Yup.number().typeError("Debe ser un número"),
  presciptor: Yup.number().typeError("Debe ser un número"),
  bank_account: Yup.string(),
});

export const tarifaSchema = (sector_type) =>
  Yup.object({
    type_oferta: Yup.string().required("Requerido"),
    subtipo_oferta: Yup.string().required("Requerido"),
    tarifa_comercial: Yup.string().required("Requerido"),
    tarifa_oferta: Yup.string().required("Requerido"),
    mode_facturacio: Yup.string().required("Requerido"),
    coeficiente_k: Yup.number()
      .typeError("Debe ser un número")
      .integer("Debe ser un número entero")
      .lessThan(36, "Debe ser menor de 36")
      .moreThan(4, "Debe ser mayor de 4")
      .required("Requerido"),
    coeficiente_d: Yup.number().required("Requerido"),
    numero_ctto: Yup.string().when("type_oferta", {
      is: "renovacion",
      then: () => Yup.string().required("Requerido"),
    }),
    showDatePicker: Yup.string().required("Requerido"),
    fecha_activacion: Yup.string()
      .nullable()
      .when("showDatePicker", {
        is: "true",
        then: () => Yup.string().required("Requerido"),
      }),
  });

export const partnerSchema = Yup.object({
  cliente_tipo_persona: Yup.string().required(
    "El tipo de persona es requerido"
  ),
  cliente_nombre: Yup.string().when("cliente_tipo_persona", {
    is: "fisica",
    then: () => Yup.string().required("Requerido"),
  }),
  cliente_apellidos: Yup.string().required("Requerido"),
  cliente_tipo_documento: Yup.string().required("Requerido"),
  cliente_vat: Yup.string().required("Requerido"),
  cliente_telefono_1: Yup.string().required("Requerido"),
  cliente_telefono_2: Yup.string().required("Requerido"),
  cliente_email_1: Yup.string()
    .email("Ingrese un correo válido")
    .required("Requerido"),
  cliente_pais: Yup.number().required("Requerido"),  
  cups_nv: Yup.string().required("Requerido"),
  cups_pnp: Yup.string().required("Requerido"),
  cups_dp: Yup.string().required("Requerido"),
  cups_id_pais: Yup.number().required("Requerido"),
  cups_id_provincia: Yup.number().required("Requerido"),
  cups_id_municipi: Yup.number().required("Requerido"),
  cups_id_poblacio: Yup.number()
    .typeError("Debe ser un número")
    .transform(emptyStringToNull)
    .nullable(),
  cups_tv: Yup.number().required("Requerido"),
  comPostal: Yup.boolean(),
}).required();

export const pagadorSchema = Yup.object({
  partnerSepa: Yup.boolean(),
  iban: Yup.string().required("Requerido"),
  cliente_tipo_persona: Yup.string().required(
    "El tipo de persona es requerido"
  ),
  cliente_nombre: Yup.string().when("partnerSepa", {
    is: false,
    then: () => Yup.string().required("Requerido"),
  }),
  cliente_apellidos: Yup.string().when("partnerSepa", {
    is: false,
    then: () => Yup.string().required("Requerido"),
  }),
  cliente_vat: Yup.string().when("partnerSepa", {
    is: false,
    then: () => Yup.string().required("Requerido"),
  }),
  cliente_telefono_1: Yup.string().when("partnerSepa", {
    is: false,
    then: () => Yup.string().required("Requerido"),
  }),
  cliente_telefono_2: Yup.string().when("partnerSepa", {
    is: false,
    then: () => Yup.string().required("Requerido"),
  }),
  cliente_email_1: Yup.string()
    .email("Ingrese un correo válido")
    .when("partnerSepa", {
      is: false,
      then: () => Yup.string().required("Requerido"),
    }),
  cups_nv: Yup.string().when("partnerSepa", {
    is: false,
    then: () => Yup.string().required("Requerido"),
  }),
  cups_pnp: Yup.string().when("partnerSepa", {
    is: false,
    then: () => Yup.string().required("Requerido"),
  }),
  cups_dp: Yup.string().when("partnerSepa", {
    is: false,
    then: () => Yup.string().required("Requerido"),
  }),
  cups_id_pais: Yup.number()
    .typeError("Debe ser un número")
    .when("partnerSepa", {
      is: false,
      then: () =>
        Yup.number().typeError("Debe ser un número").required("Requerido"),
    }),
  cups_id_provincia: Yup.number()
    .typeError("Debe ser un número")
    .when("partnerSepa", {
      is: false,
      then: () =>
        Yup.number().typeError("Debe ser un número").required("Requerido"),
    }),
  cups_id_municipi: Yup.number()
    .typeError("Debe ser un número")
    .when("partnerSepa", {
      is: false,
      then: () =>
        Yup.number().typeError("Debe ser un número").required("Requerido"),
    }),
  cups_id_poblacio: Yup.number()
    .typeError("Debe ser un número")
    .transform(emptyStringToNull)
    .nullable(),
  cups_tv: Yup.number()
    .typeError("Debe ser un número")
    .when("partnerSepa", {
      is: false,
      then: () =>
        Yup.number().typeError("Debe ser un número").required("Requerido"),
    }),
}).required();

export const cupsSchema = (sector_type) =>
  Yup.object({
    mismaDireccion: Yup.boolean().notRequired(),
    autoconsumo_id: Yup.string().test(
      "autoconsumo_required",
      "Requerido",
      (value) =>
        sector_type !== "electrico" || (sector_type === "electrico" && value)
    ),
    // caudal_diario: Yup.string().test(
    //   "caudal_required",
    //   "Requerido",
    //   (value) => sector_type === "electrico" || (sector_type !== "electrico" && value)
    // ),
    // pressio_cups: Yup.string().test(
    //   "pressio_required",
    //   "Requerido",
    //   (value) => sector_type === "electrico" || (sector_type !== "electrico" && value)
    // ),
    cnae: Yup.string().required("Requerido"),
    cnae_desc: Yup.string().test("empty-cnae", "Requerido", function (value) {
      const match = "No existe el CNAE";
      return value !== match;
    }),
    //cups_ref_catastral: Yup.string().required("Requerido"),
    /*consumo_anual_previsto: Yup.number()
      .integer("Debe ser un número entero")
      .positive("Debe ser mayor a 0")
      .typeError("Debe ser un número entero")
      .required("Requerido"),*/
    cups_distribuidora_id: Yup.number()
      .typeError("Debe ser un número")
      .required("Requerido"),
    cups_distribuidora_name: Yup.string(),
    cups_name: Yup.string()
      .nullable()
      //.transform((o, c) => (o === "" ? null : c))
      .min(18, "Cantidad de caracteres inválida")
      .max(22, "Cantidad de caracteres inválida"),
    cups_nv: Yup.string().when("mismaDireccion", {
      is: false,
      then: () => Yup.string().required("Requerido"),
    }),
    cups_pnp: Yup.string().when("mismaDireccion", {
      is: false,
      then: () => Yup.string().required("Requerido"),
    }),
    cups_dp: Yup.string().when("mismaDireccion", {
      is: false,
      then: () => Yup.string().required("Requerido"),
    }),
    cups_id_pais: Yup.number()
      .typeError("Debe ser un número")
      .when("mismaDireccion", {
        is: false,
        then: () =>
          Yup.number().typeError("Debe ser un número").required("Requerido"),
      }),
    cups_id_provincia: Yup.number()
      .typeError("Debe ser un número")
      .when("mismaDireccion", {
        is: false,
        then: () =>
          Yup.number().typeError("Debe ser un número").required("Requerido"),
      }),
    cups_id_municipi: Yup.number()
      .typeError("Debe ser un número")
      .when("mismaDireccion", {
        is: false,
        then: () =>
          Yup.number().typeError("Debe ser un número").required("Requerido"),
      }),
    cups_id_poblacio: Yup.number()
      .typeError("Debe ser un número")
      .transform(emptyStringToNull)
      .nullable(),
    cups_tv: Yup.number()
      .typeError("Debe ser un número")
      .when("mismaDireccion", {
        is: false,
        then: () =>
          Yup.number().typeError("Debe ser un número").required("Requerido"),
      }),
  }).required();
