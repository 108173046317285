import Layout from "../../hocs/Layout";
import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Col,
  Row,
  Table,
  Badge,
  Container,
  Stack,
} from "react-bootstrap";
import { connect } from "react-redux";
import { get_ofertas_erp, aceptar_oferta } from "../../services/ofertas";
import { print_contrato_pdf } from "../../services/auxiliares";
import { FaCheck, FaPhoneAlt, FaPenFancy } from "react-icons/fa";
import { CiWarning } from "react-icons/ci";
import { MdEdit, MdDownload } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import EditarOferta from "../../components/modals/EditarOferta";

const estados = {
  esborrany: { descrip: "BORRADOR", bg: "primary" },
  aceptada: { descrip: "ACEPTADA", bg: "success" },
};

const tipoOferta = {
  simple: "CONTRATO NUEVO",
  renovacion: "RENOVACIÓN",
  // vuelve: "VUELVE??",
};

const OfertasList = ({ user, isAuthenticated }) => {
  useEffect(() => {
    const getOfertas = async () => {
      setLoading(true);
      setOfertasErp(await get_ofertas_erp(user.prescriptor));
      setLoading(false);
    };
    if (isAuthenticated) {
      getOfertas();
    }
    window.scrollTo(0, 0);
  }, []);

  const [showCartera, setShowCartera] = useState(false);
  const [oferta, setOferta] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ofertas_erp, setOfertasErp] = useState(false);

  const getOfertas = async () => {
    setLoading(true);
    setOfertasErp(await get_ofertas_erp(user.prescriptor));
    setLoading(false);
  };

  const aceptarOferta = async (id, sector_type) => {
    await aceptar_oferta(id, user.id, sector_type);
    setOfertasErp(await get_ofertas_erp(user.prescriptor));
  };

  const contratoPdf = async (user_id, id, polissa_id) => {
    try {
      const archivo = await print_contrato_pdf(user_id, id, polissa_id);
      Object.keys(archivo).forEach((arch) => {
        const { datas: base64Data, name: originalName } = archivo[arch];
        const { contentType, extension } = determineContentTypeAndExtension(base64Data);
  
        // Decodifica el base64
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
  
        // Crea un Blob con el tipo MIME adecuado
        const blob = new Blob([byteArray], { type: contentType });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        
        // Usa el nombre original si está disponible, o genera un nombre genérico
        link.download = originalName || `archivo.${extension}`;
        link.click();
        URL.revokeObjectURL(link.href);
      });
    } catch (error) {
      console.error("Error al descargar el archivo:", error);
    }
    /*let pdfWindow = window.open("");
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(files) +
        "'></iframe>"
    );
    pdfWindow.document.title = "AB Energía - Agentes";*/
    getOfertas();
  };

    // Función para determinar el tipo MIME y la extensión
    const determineContentTypeAndExtension = (base64Data) => {
      if (base64Data.startsWith("iVBORw0KGgo")) {
        return { contentType: "image/png", extension: "png" };
      }
      if (base64Data.startsWith("/9j/")) {
        return { contentType: "image/jpeg", extension: "jpg" };
      }
      if (base64Data.startsWith("JVBERi0")) {
        return { contentType: "application/pdf", extension: "pdf" };
      }
      // Añade más tipos si es necesario
      return { contentType: "application/octet-stream", extension: "bin" }; // Valor por defecto
    };

  const openModalCartera = (id, name, sector_type) => {
    setOferta({ id, name, sector_type });
    setShowCartera(true);
  };

  const sectorTypeBadge = (sector_type) => {
    return sector_type === "electrico" ? (
      <Badge pill bg="warning" text="dark">
        ELECTRICIDAD
      </Badge>
    ) : (
      <Badge pill bg="info" text="dark">
        GAS
      </Badge>
    );
  };

  const stateBadge = (state) => {
    return (
      <Badge pill bg={estados[state].bg}>
        {estados[state].descrip}
      </Badge>
    );
  };

  return (
    <Layout lg={10} md={12} title="Ofertas">
      <div className="row justify-content-center align-items-center mt-5">
        {loading ? (
          <></>
        ) : ofertas_erp ? (
          <>
            {/* <h1 className="mt-2 mb-5">Ofertas</h1> */}
            <Table striped hover responsive>
              <thead>
                <tr>
                  <th className="col-lg-1 col-md-1">Nº oferta</th>
                  <th className="col-lg-1 col-md-1">Sector/Tipo oferta</th>
                  {/* <th className="col-lg-1">Tipo oferta</th> */}
                  <th className="col-lg-1 col-md-1">Etapa</th>
                  <th className="col-lg-2 col-md-2">Nota</th>
                  {/* <th>Documento</th> */}
                  <th className="col-lg-2 col-md-2">Cliente</th>
                  <th className="col-lg-2 col-md-2">Contacto</th>
                  <th className="col-lg-3 col-md-3">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {ofertas_erp.map((oferta) => {
                  return (
                    <tr key={oferta.name} className="align-middle">
                      <td>{oferta.name}</td>
                      <td>
                        {sectorTypeBadge(oferta.sector_type)}
                        <br />
                        <Badge pill bg="dark">
                          {tipoOferta[oferta.type]}
                        </Badge>
                      </td>
                      {/* <td>{sectorTypeBadge(oferta.sector_type)}</td> */}
                      <td>
                        <Badge pill bg="primary">
                          {oferta.state}
                        </Badge>
                      </td>
                      {/* <td>Fecha de activacion: Cuanto antes, máximo 30 días</td> */}
                      <td>{oferta.text_note.text_note}</td>
                      {/* <td>{oferta.cliente_vat ? oferta.cliente_vat : "—"}</td> */}
                      <td>
                        {oferta.cliente_apellidos ||
                        oferta.cliente_nombre ||
                        oferta.cliente_vat ? (
                          <>
                            <span>
                              {oferta.cliente_apellidos ||
                              oferta.cliente_nombre ? (
                                <span>
                                  {oferta.cliente_apellidos},{" "}
                                  {oferta.cliente_nombre}
                                </span>
                              ) : (
                                <span> — </span>
                              )}
                            </span>
                            <br />
                            <span>
                              {oferta.cliente_vat ? (
                                <span>
                                  <u>DNI</u>: {oferta.cliente_vat}
                                </span>
                              ) : (
                                <span> — </span>
                              )}
                            </span>
                          </>
                        ) : (
                          "—"
                        )}
                      </td>
                      <td>
                        {
                          <Container>
                            {oferta.cliente_telefono_1 && (
                              <Row className="justify-content-start align-items-center">
                                <Col md="auto" className="p-0">
                                  <FaPhoneAlt />
                                </Col>
                                <Col md="auto">
                                  {oferta.cliente_telefono_1.replaceAll(
                                    " ",
                                    ""
                                  )}
                                </Col>
                              </Row>
                            )}
                            {oferta.cliente_email && (
                              <Row className="justify-content-start align-items-center">
                                <Col md="auto" className="p-0">
                                  <IoMdMail />
                                </Col>
                                <Col md="auto">{oferta.cliente_email}</Col>
                              </Row>
                            )}
                            {!oferta.cliente_email &&
                              !oferta.cliente_telefono_1 &&
                              " — "}
                          </Container>
                        }
                      </td>
                      <td>
                        <Row className="align-items-center justify-content-center my-1">
                          <Col md="8">
                            <Stack gap={2}>
                            { oferta.type === "simple" ? (
                                oferta.state === "Solicitud oferta" ? (
                                  <>
                                    <Button
                                      className="button"
                                      type="submit"
                                      onClick={() =>
                                        aceptarOferta(
                                          oferta.id,
                                          oferta.sector_type
                                        )
                                      }
                                      title="Aceptar oferta"
                                    >
                                      <Stack direction="horizontal" gap={2}>
                                        <FaCheck />
                                        Aceptar oferta
                                      </Stack>
                                    </Button>
                                    <Button
                                      className="button"
                                      type="submit"
                                      onClick={() =>
                                        openModalCartera(
                                          oferta.id,
                                          oferta.name,
                                          oferta.sector_type
                                        )
                                      }
                                      title="Modificar datos"
                                    >
                                      <Stack direction="horizontal" gap={2}>
                                        <MdEdit />
                                        Modificar datos
                                      </Stack>
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      className="button"
                                      type="submit"
                                      onClick={() =>
                                        contratoPdf(
                                          oferta.user_id,
                                          oferta.id,
                                          oferta.polissa_id[0]
                                        )
                                      }
                                      title="Imprimir contrato"
                                    >
                                      <Stack direction="horizontal" gap={2}>
                                        <MdDownload />
                                        Descargar contrato
                                      </Stack>
                                    </Button>
                                    <a
                                      href="https://admin.clickandsign.eu/login/"
                                      target="_blank"
                                      className="button btn btn-primary"
                                      title="Click and Sign"
                                    >
                                      <Stack direction="horizontal" gap={2}>
                                        <FaPenFancy />
                                        Click & Sign
                                      </Stack>
                                    </a>
                                  </>
                                )
                              ):(
                                 oferta.state === "Solicitud oferta" ? (
                                  <>
                                    <Button
                                      className="button"
                                      type="submit"
                                      onClick={() =>
                                        contratoPdf(
                                          oferta.user_id,
                                          oferta.id,
                                          oferta.polissa_id[0]
                                        )
                                      }
                                      title="Imprimir contrato"
                                    >
                                      <Stack direction="horizontal" gap={2}>
                                        <MdDownload />
                                        Descargar contrato
                                      </Stack>
                                    </Button>
                                    <Button
                                      className="button"
                                      type="submit"
                                      onClick={() =>
                                        openModalCartera(
                                          oferta.id,
                                          oferta.name,
                                          oferta.sector_type
                                        )
                                      }
                                      title="Modificar datos"
                                    >
                                      <Stack direction="horizontal" gap={2}>
                                        <MdEdit />
                                        Modificar datos
                                      </Stack>
                                    </Button>
                                  </>
                                  ):(
                                  <>
                                    <Button
                                      className="button"
                                      type="submit"
                                      onClick={() =>
                                        contratoPdf(
                                          oferta.user_id,
                                          oferta.id,
                                          oferta.polissa_id[0]
                                        )
                                      }
                                      title="Imprimir contrato"
                                    >
                                      <Stack direction="horizontal" gap={2}>
                                        <MdDownload />
                                        Descargar contrato
                                      </Stack>
                                    </Button>
                                    <a
                                      href="https://admin.clickandsign.eu/login/"
                                      target="_blank"
                                      className="button btn btn-primary"
                                      title="Click and Sign"
                                    >
                                      <Stack direction="horizontal" gap={2}>
                                        <FaPenFancy />
                                        Click & Sign
                                      </Stack>
                                    </a>
                                  </>
                                )
                                
                              )
                            }
                            </Stack>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        ) : (
          <>
            <Row>
              <Col>
                <h1 className="error-title">
                  <CiWarning />
                </h1>
                <h1 className="text-gray">No hay ofertas disponibles.</h1>
              </Col>
            </Row>
            <Row className="justify-content-center mt-2">
              <Col md="auto">
                <Button
                  className="button rounded-5 button-block"
                  type="submit"
                  variant="secondary"
                  onClick={() => getOfertas()}
                >
                  Reintentar
                </Button>
              </Col>
            </Row>
          </>
        )}
      </div>
      <EditarOferta show={showCartera} close={setShowCartera} oferta={oferta} />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.Auth.user,
  isAuthenticated: state.Auth.isAuthenticated,
});

export default connect(mapStateToProps)(OfertasList);
