import Layout from "../../hocs/Layout";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { setLoader, removeLoader } from "../../redux/actions/loader";
import {
  get_paises,
  get_provincias,
  get_municipios,
  get_poblaciones,
  get_tipovia,
} from "../../redux/actions/auxiliares";
import { Button, Col, Form, Row, Container, Card } from "react-bootstrap";
import { TextInput, SelectInput } from "../../components/inputs/Inputs";
import { get_datos_oferta_erp, set_oferta_erp } from "../../services/ofertas";
import {
  get_partner_by_vat,
  find_or_create_partner,
  set_partner_erp,
} from "../../services/partner";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { partnerSchema } from "../../utils/validationSchemas";

const OfertaPartner = ({
  paises,
  provincias,
  municipios,
  poblaciones,
  tipovia,
  get_paises,
  get_provincias,
  get_municipios,
  get_poblaciones,
  get_tipovia,
  setLoader,
  removeLoader,
}) => {
  const params = useParams();
  const sector_type = params.sector_type;
  useEffect(() => {
    const get_datos_iniciales = async () => {
      setLoader("Cargando datos iniciales");
      await get_paises();
      await get_tipovia();
      removeLoader();
      setOfertaErp(await get_datos_oferta_erp(params.oferta_id));
    };
    get_datos_iniciales();
    // window.scrollTo(0, 0);
  }, []);

  const defaultValues = {
    cliente_tipo_persona: "",
    cliente_nombre: "",
    cliente_apellidos: "",
    cliente_tipo_documento: "",
    cliente_vat: "",
    cliente_telefono_1: "",
    cliente_telefono_2: "",
    cliente_email_1: "",
    cliente_pais: "",
    cups_aclarador: "",
    cups_nv: "",
    cups_pt: "",
    cups_pu: "",
    cups_es: "",
    cups_pnp: "",
    cups_dp: "",
    cups_id_pais: "",
    cups_id_provincia: "",
    cups_id_municipi: "",
    cups_id_poblacio: "",
    cups_tv: "",
    comPostal: false,
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    reset,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(partnerSchema),
  });

  const navigate = useNavigate();
  const [oferta_erp, setOfertaErp] = useState(null);
  const [partner, setPartner] = useState(null);
  const [loadingProv, setLoadingProv] = useState(false);
  const [loadingMuni, setLoadingMuni] = useState(false);
  const [loadingPob, setLoadingPob] = useState(false);
  const [partnerCreado, setPartnerCreado] = useState(false);

  useEffect(() => {
    if (oferta_erp) {
      const getPartner = async () => {
        setPartner(await get_partner_by_vat(oferta_erp.cliente_vat));
      };
      if (oferta_erp.cliente_vat) {
        getPartner();
      }
      const envio =
        sector_type === "electrico"
          ? oferta_erp.envio_postal
          : oferta_erp.envio_postal_gas;
      setValue("comPostal", envio);
    }
  }, [oferta_erp]);

  useEffect(() => {
    if (partner) {
      const cliente_tipo_documento = oferta_erp.cliente_tipo_documento ?? getValues().cliente_tipo_documento;
      const comPostal = getValues().comPostal;
      reset({
        cliente_tipo_documento,
        comPostal,
        cliente_tipo_persona: oferta_erp.cliente_tipo_persona,
        cliente_nombre: partner.first_name,
        cliente_apellidos: partner.last_name,
        cliente_vat: partner.vat,
        cliente_telefono_1: partner.phone,
        cliente_telefono_2: partner.mobile,
        cliente_email_1: partner.email,
        cliente_pais: partner.country_id[0],
        cups_aclarador: partner.aclarador,
        cups_nv: partner.nv,
        cups_pt: partner.pt,
        cups_pu: partner.pu,
        cups_es: partner.es,
        cups_pnp: partner.pnp,
        cups_dp: partner.zip,
        cups_id_pais: partner.country[0],
        cups_tv: partner.tv[0],
      });
      const setLocation = async () => {
        setLoader("Cargando cliente");
        await get_provincias(partner.country[0]);
        await get_municipios(partner.state_id[0]);
        await get_poblaciones(partner.id_municipi[0]);
        setValue("cups_id_provincia", partner.state_id[0]);
        setValue("cups_id_municipi", partner.id_municipi[0]);
        setValue("cups_id_poblacio", partner.id_poblacio ? partner.id_poblacio[0] : "",{shouldValidate: true});
        removeLoader();
      };
      setLocation();
    }
  }, [partner]);

  const fetchProvincias = async (paisId) => {
    if (parseInt(paisId)) {
      setLoadingProv(true);
      setValue("cups_id_provincia", "");
      setValue("cups_id_municipi", "");
      setValue("cups_id_poblacio", "");
      await get_provincias(paisId);
      setLoadingProv(false);
    }
  };

  const fetchMunicipios = async (provId) => {
    if (parseInt(provId)) {
      setLoadingMuni(true);
      setValue("cups_id_municipi", "");
      setValue("cups_id_poblacio", "");
      await get_municipios(provId);
      setLoadingMuni(false);
    }
  };

  const fetchPoblaciones = async (muniId) => {
    if (parseInt(muniId)) {
      setLoadingPob(true);
      setValue("cups_id_poblacio", "");
      await get_poblaciones(muniId);
      setLoadingPob(false);
    }
  };

  const onSearchPartner = async () => {
    const data = getValues();
    const partnerRes = await get_partner_by_vat(data.cliente_vat);
    if (!partnerRes) {
      reset({ ...defaultValues, cliente_vat: data.cliente_vat });
    }
    setPartner(partnerRes);
  };

  const onSubmit = async () => {
    const elec = sector_type === "electrico";
    const data = getValues();
    const datos = {
      cliente_tipo_persona: data.cliente_tipo_persona,
      cliente_nombre: data.cliente_tipo_persona === "fisica" ? data.cliente_nombre : "",
      cliente_apellidos: data.cliente_apellidos,
      cliente_vat: data.cliente_vat,
      cliente_telefono_1: data.cliente_telefono_1,
      cliente_telefono_2: data.cliente_telefono_2,
      cliente_email_1: data.cliente_email_1,
      country_id: data.cups_id_pais,
      country: data.cliente_pais,
      cups_id_provincia: data.cups_id_provincia,
      cups_id_municipi: data.cups_id_municipi,
      cups_id_poblacio: data.cups_id_poblacio,
      cups_aclarador: data.cups_aclarador,
      cups_tv: data.cups_tv,
      cups_nv: data.cups_nv,
      cups_pt: data.cups_pt,
      cups_pu: data.cups_pu,
      cups_es: data.cups_es,
      cups_pnp: data.cups_pnp,
      cups_dp: data.cups_dp,
      cups_cpo: null,
      cups_cpa: null,
    };
    const datosPartnerOferta = {
      cliente_tipo_persona: data.cliente_tipo_persona,
      cliente_nombre: data.cliente_tipo_persona === "fisica" ? data.cliente_nombre : "",
      cliente_apellidos: data.cliente_apellidos,
      cliente_tipo_documento: data.cliente_tipo_documento,
      cliente_vat: data.cliente_vat,
      cliente_telefono_1: data.cliente_telefono_1,
      cliente_telefono_2: data.cliente_telefono_2,
      cliente_email_1: data.cliente_email_1,
      [elec ? "envio_postal" : "envio_postal_gas"]: data.comPostal,
    };
    const partnerNuevo = partner
      ? await set_partner_erp(partner.id, datos) //si se decidiese cambiar el partner que buscamos, sobreescribimos una persona con otra, manteniendo el id
      : await find_or_create_partner(datos);
    datosPartnerOferta.partner_id = partnerNuevo.id;
    datosPartnerOferta.cliente_direccion = partnerNuevo.address[0];
    datosPartnerOferta.contacto_direccion = partnerNuevo.address[0];
    await set_oferta_erp(
      params.oferta_id,
      datosPartnerOferta,
      params.sector_type
    );
    setPartnerCreado(true);
  };

  if (partnerCreado && oferta_erp) {
    return <Navigate to={"/" + sector_type + "/pagador/" + oferta_erp.id} />;
  }

  const idPais = watch("cups_id_pais");
  const idProv = watch("cups_id_provincia");
  const idMuni = watch("cups_id_municipi");
  const tipoPersona = watch("cliente_tipo_persona");
  const tipoDocumento = watch("cliente_tipo_documento");
  const clienteVat = watch("cliente_vat");

  return (
    <Layout lg="6" md="8" title="Datos del cliente">
      <Card className="card-label-corner">
        {/* <Card.Header
          className={
            sector_type === "electrico" ? "ab-primary-elec" : "ab-primary-gas"
          }
        >
          <Row className="align-items-center justify-content-center my-1">
            <Col className="text-center" md={{ span: 9, offset: 1 }}>
              <h1 className="text-white form-label m-0">Datos del cliente</h1>
            </Col>
            <Col md="2">
              <img
                style={{ backgroundColor: "white", borderRadius: "50%" }}
                src={sector_type === "electrico" ? logo_electricidad : logo_gas}
                className="img-fluid p-2"
                width="40"
                title={sector_type === "electrico" ? "Electricidad" : "Gas"}
                alt={sector_type === "electrico" ? "Electricidad" : "Gas"}
              />
            </Col>
          </Row>
        </Card.Header> */}
        <Card.Body>
          <Row className="justify-content-center align-items-center mt-2">
            <Col>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <DevTool control={control} />
                <Row className="justify-content-start align-items-center mb-2">
                  <Col lg={3} md={12}>
                    <SelectInput
                      register={register}
                      name="cliente_tipo_documento"
                      label="Tipo de documento"
                      error={errors.cliente_tipo_documento?.message}
                    >
                      <option key="null" value="">
                        Tipo de documento
                      </option>
                      <option key="nif" value="nif">
                        NIF
                      </option>
                      <option key="cif" value="cif">
                        CIF
                      </option>
                      <option key="nie" value="nie">
                        NIE
                      </option>
                      <option key="pasaporte" value="nie">
                        Pasaporte
                      </option>
                    </SelectInput>
                  </Col>
                  <Col lg={3} md={7}>
                    <TextInput
                      register={register}
                      name="cliente_vat"
                      label="Nº Documento"
                      error={errors.cliente_vat?.message}
                      disabled={partner} 
                    />
                  </Col>
                  <Col lg="auto" md={5} className="align-self-baseline mt-2">
                    <Button
                      className="button rounded-5"
                      onClick={onSearchPartner}
                      disabled={!clienteVat || !tipoDocumento}
                    >
                      Buscar Cliente
                    </Button>
                  </Col>
                  <Col lg="auto" md={7}>
                    <SelectInput
                      register={register}
                      name="cliente_pais"
                      label="Nacionalidad"
                      error={errors.cups_id_pais?.message}
                      onChange={(e) => {
                        setValue("cliente_pais", e.target.value);
                      }}
                    >
                      {paises &&
                        paises.map((pais) => {
                          return (
                            <option
                              selected={!pais.id}
                              disabled={!pais.id}
                              key={pais.id}
                              name="pais"
                              id="pais"
                              value={pais.id}
                            >
                              {pais.name}
                            </option>
                          );
                        })}
                    </SelectInput>
                  </Col>
                </Row>
                <Row className="justify-content-start align-items-center">
                  <Col lg={4} md={7}>
                    <SelectInput
                      register={register}
                      name="cliente_tipo_persona"
                      label="Tipo de persona"
                      error={errors.cliente_tipo_persona?.message}
                    >
                      <option key="null" value="" disabled>
                        Seleccione tipo de persona
                      </option>
                      <option value="fisica">Física</option>
                      <option value="juridica">Jurídica</option>
                    </SelectInput>
                  </Col>
                  {tipoPersona === "fisica" && (
                    <Col lg={4} md={6}>
                      <TextInput
                        register={register}
                        name="cliente_nombre"
                        label="Nombre"
                        error={errors.cliente_nombre?.message}
                      />
                    </Col>
                  )}
                  <Col lg={4} md={6}>
                    <TextInput
                      register={register}
                      name="cliente_apellidos"
                      label={tipoPersona !== "fisica" ? "Empresa" : "Apellido"}
                      error={errors.cliente_apellidos?.message}
                    />
                  </Col>
                </Row>
                <Row className="justify-content-start align-items-center">
                  <Col lg={4} md={6}>
                    <TextInput
                      register={register}
                      name="cliente_email_1"
                      label="Email"
                      error={errors.cliente_email_1?.message}
                    />
                  </Col>
                  <Col lg={4} md={6}>
                    <TextInput
                      register={register}
                      name="cliente_telefono_1"
                      label="Teléfono"
                      error={errors.cliente_telefono_1?.message}
                    />
                  </Col>
                  <Col lg={4} md={6}>
                    <TextInput
                      register={register}
                      name="cliente_telefono_2"
                      label="Teléfono móvil"
                      error={errors.cliente_telefono_2?.message}
                    />
                  </Col>
                </Row>
                <Row className="justify-content-start align-items-center">
                  <Col md={6}>
                    <SelectInput
                      register={register}
                      name="cups_id_pais"
                      label="País"
                      error={errors.cups_id_pais?.message}
                      onChange={(e) => {
                        setValue("cups_id_pais", e.target.value);
                        fetchProvincias(e.target.value);
                      }}
                    >
                      {paises &&
                        paises.map((pais) => {
                          return (
                            <option
                              selected={!pais.id}
                              disabled={!pais.id}
                              key={pais.id}
                              name="pais"
                              id="pais"
                              value={pais.id}
                            >
                              {pais.name}
                            </option>
                          );
                        })}
                    </SelectInput>
                  </Col>
                  <Col md={loadingProv ? 5 : 6}>
                    <SelectInput
                      register={register}
                      name="cups_id_provincia"
                      label="Provincia"
                      error={errors.cups_id_provincia?.message}
                      onChange={(e) => {
                        setValue("cups_id_provincia", e.target.value);
                        fetchMunicipios(e.target.value);
                      }}
                      disabled={!idPais || loadingProv || !provincias || !provincias.length}
                    >
                      {loadingProv ? (
                        <option>Cargando provincias...</option>
                      ) : (
                        provincias &&
                        provincias.map((prov) => {
                          return (
                            <option
                              selected={!prov.id}
                              disabled={!prov.id}
                              key={prov.id}
                              name="provincia"
                              id="provincia"
                              value={prov.id}
                            >
                              {prov.name}
                            </option>
                          );
                        })
                      )}
                    </SelectInput>
                  </Col>
                  {loadingProv && (
                    <Col md={1} className="align-self-baseline mt-3">
                      {loadingProv && (
                        <option className="spinner-border spinner-border text-ab"></option>
                      )}
                    </Col>
                  )}
                </Row>
                <Row className="justify-content-start align-items-center">
                  <Col md={loadingMuni ? 5 : 6}>
                    <SelectInput
                      register={register}
                      name="cups_id_municipi"
                      label="Municipio"
                      error={errors.cups_id_municipi?.message}
                      onChange={(e) => {
                        setValue("cups_id_municipi", e.target.value);
                        fetchPoblaciones(e.target.value);
                      }}
                      disabled={!idProv || loadingMuni || !municipios || !municipios.length}
                    >
                      {loadingMuni ? (
                        <option>Cargando municipios...</option>
                      ) : (
                        municipios &&
                        municipios.map((muni) => {
                          return (
                            <option
                              selected={!muni.id}
                              disabled={!muni.id}
                              key={muni.id}
                              name="municipio"
                              id="municipio"
                              value={muni.id}
                            >
                              {muni.name}
                            </option>
                          );
                        })
                      )}
                    </SelectInput>
                  </Col>
                  {loadingMuni && (
                    <Col md={1} className="align-self-baseline mt-3">
                      {loadingMuni && (
                        <option className="spinner-border spinner-border text-ab"></option>
                      )}
                    </Col>
                  )}
                  <Col md={loadingPob ? 5 : 6}>
                    <SelectInput
                      register={register}
                      name="cups_id_poblacio"
                      label="Población"
                      error={errors.cups_id_poblacio?.message}
                      disabled={!parseInt(idMuni) || loadingPob || !poblaciones || !poblaciones.length}
                    >
                      {loadingPob ? (
                        <option>Cargando poblaciones...</option>
                      ) : (
                        poblaciones &&
                        poblaciones.map((pobla) => {
                          return (
                            <option
                              selected={!pobla.id}
                              disabled={!pobla.id}
                              key={pobla.id}
                              name="cups_id_poblacio"
                              id="cups_id_poblacio"
                              value={pobla.id}
                            >
                              {pobla.name}
                            </option>
                          );
                        })
                      )}
                    </SelectInput>
                  </Col>
                  {loadingPob && (
                    <Col md={1} className="align-self-baseline mt-3">
                      {loadingPob && (
                        <option className="spinner-border spinner-border text-ab"></option>
                      )}
                    </Col>
                  )}
                </Row>
                <Row className="justify-content-start align-items-center">
                  <Col lg={3} md={4}>
                    <SelectInput
                      register={register}
                      name="cups_tv"
                      label="Tipo de vía"
                      error={errors.cups_tv?.message}
                    >
                      {tipovia &&
                        tipovia.map((via) => {
                          return (
                            <option
                              key={via.id}
                              name="tipovia"
                              id="tipovia"
                              value={via.id}
                            >
                              {via.name}
                            </option>
                          );
                        })}
                    </SelectInput>
                  </Col>
                  <Col lg={6} md={8}>
                    <TextInput
                      register={register}
                      name="cups_nv"
                      label="Calle"
                      error={errors.cups_nv?.message}
                    />
                  </Col>
                  <Col lg={3} md={12}>
                    <TextInput
                      register={register}
                      name="cups_pnp"
                      label="Número"
                      error={errors.cups_pnp?.message}
                    />
                  </Col>
                </Row>
                <Row className="justify-content-start align-items-center">
                  <Col lg={3} md={6} xs={6}>
                    <TextInput
                      register={register}
                      name="cups_es"
                      label="Escalera"
                      error={errors.cups_es?.message}
                    />
                  </Col>
                  <Col lg={3} md={6} xs={6}>
                    <TextInput
                      register={register}
                      name="cups_pt"
                      label="Planta"
                      error={errors.cups_pt?.message}
                    />
                  </Col>

                  <Col lg={3} md={6} xs={6}>
                    <TextInput
                      register={register}
                      name="cups_pu"
                      label="Puerta"
                      error={errors.cups_pu?.message}
                    />
                  </Col>
                  <Col lg={3} md={6} xs={6}>
                    <TextInput
                      register={register}
                      name="cups_dp"
                      label="C.P."
                      error={errors.cups_dp?.message}
                    />
                  </Col>
                </Row>
                <Row className="justify-content-between align-items-center mb-2">
                  <Col lg={8} md={12}>
                    <TextInput
                      register={register}
                      name="cups_aclarador"
                      label="Aclarador"
                      error={errors.cups_aclarador?.message}
                    />
                  </Col>
                  <Col md="auto">
                    <Form.Check
                      {...register("comPostal")}
                      id="comCheckbox"
                      type="checkbox"
                      label="Recibir factura via postal"
                    />
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col lg={8} md={12}>
                    <Row className="justify-content-evenly mx-5">
                      <Col className="mb-2">
                        <Button
                          className="button rounded-5 button-block"
                          type="submit"
                          disabled={!isValid}
                        >
                          Guardar
                        </Button>
                      </Col>
                      {params.oferta_id && (
                        <Col>
                          <Button
                            className="button rounded-5 button-secondary button-block"
                            type="submit"
                            variant="secondary"
                            onClick={() => navigate("/contratos/ofertas")}
                          >
                            Volver a ofertas
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  let props = {
    paises: state.Auxiliares.paises,
    provincias: state.Auxiliares.provincias,
    municipios: state.Auxiliares.municipios,
    poblaciones: state.Auxiliares.poblaciones,
    tipovia: state.Auxiliares.tipovia,
    user: state.Auth.user,
  };
  return props;
};

export default connect(mapStateToProps, {
  get_paises,
  get_provincias,
  get_municipios,
  get_poblaciones,
  get_tipovia,
  setLoader,
  removeLoader,
})(OfertaPartner);
