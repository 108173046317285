import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal, Row, Col, Button, Table, Stack, Badge } from "react-bootstrap";
import { get_facturas, get_factura_by_id } from "../../services/contratos";
import { CiWarning } from "react-icons/ci";
import { MdDownload } from "react-icons/md";

function GestionFacturas({ contrato, show, close }) {
  const [loading, setLoading] = useState(false);
  const [facturas, setfacturas] = useState(false);

  const estados = {
    open: { descrip: "EMITIDA", bg: "primary" },
    cancel: { descrip: "CANCELADA", bg: "danger" },
    paid: { descrip: "PAGADA", bg: "success" },
    proforma2: { descrip: "PROFORMA", bg: "danger" },
    impagada: { descrip: "IMPAGADA", bg: "danger" },
  };

  useEffect(() => {
    if (show) {
      const getfacturas = async () => {
        setLoading(true);
        setfacturas(await get_facturas(contrato.name,contrato.sector_type));
        setLoading(false);
      };
      getfacturas();
    }
  }, [show]);

  const cerrar = () => {
    close(false);
  };

  const stateBadge = (state) => {
    return (
      <Badge pill bg={estados[state].bg}>
        {estados[state].descrip}
      </Badge>
    );
  };

  const descargarfactura = async (invoice_id) => {
    try {
      const factura = await get_factura_by_id(invoice_id,contrato.sector_type);
      let pdfWindow = window.open("");
      pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
          encodeURI(factura) +
          "'></iframe>"
      );
      pdfWindow.document.title = "AB Energía - Agentes";
    } catch (error) {}
  };

  const getfacturas = async () => {
    setLoading(true);
    setfacturas(await get_facturas(contrato.polissa_name,contrato.sector_type));
    setLoading(false);
  };

  return (
    <Modal
      show={show}
      size="lg"
      backdrop="static"
      className="border border-0"
      centered
    >
      <Modal.Header className="ab-primary justify-content-between align-items-center">
        <Col md="auto">
          <h2 className="text-white my-0">Gestión de Facturas</h2>
        </Col>
        <Col md="auto">
          <h2 className="text-white my-0">Nº {contrato.name}</h2>
        </Col>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center align-items-center">
          {loading ? (
            <Col
              style={{ height: "40vh" }}
              className="justify-content-center d-flex"
            >
              <Stack direction="horizontal" gap={2}>
                <span className="spinner-border spinner-border text-ab"></span>
                <h3 className="mb-0">Cargando facturas</h3>
              </Stack>
            </Col>
          ) : facturas ? (
            <Col
              style={{ height: "40vh", overflow: "scroll" }}
              className="px-2"
            >
              <Table
                maxHeight="20px"
                striped
                bordered
                hover
                size="lg"
                responsive
                className="overflow-hidden"
              >
                <thead>
                  <tr>
                    <th>Factura</th>
                    <td>Fecha</td>
                    <td>Importe</td>
                    <td>Estado</td>
                    <th className={"w-25"}>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {facturas &&
                    facturas.map((factura) => {
                      return (
                        <tr key={factura.id} className="align-middle">
                          <td>{factura.number}</td>
                          <td>{factura.date_invoice}</td>
                          <td>{factura.amount_total}</td>
                          <td>{stateBadge(factura.state)}</td>
                          <td>
                            <Row className="align-items-center justify-content-center my-1">
                              <Col md="auto" style={{ paddingRight: 0 + "px" }}>
                                <Button
                                  size="lg"
                                  className="ab-secondary button rounded-pill"
                                  title="Descargar factura"
                                  onClick={() => descargarfactura(factura.id)}
                                >
                                  <MdDownload />
                                </Button>
                              </Col>
                            </Row>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Col>
          ) : (
            <Col md="12" style={{ height: "40vh" }} className="pt-4">
              <Row className="justify-content-center">
                <Col md="auto">
                  <h1 className="error-title d-flex justify-content-center">
                    <CiWarning />
                  </h1>
                  <h1 className="text-gray">No hay facturas disponibles.</h1>
                </Col>
              </Row>
              <Row className="justify-content-center mt-2">
                <Col md="auto">
                  <Button
                    className="button rounded-5 button-block"
                    variant="secondary"
                    onClick={() => getfacturas()}
                  >
                    Reintentar
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Col md="auto">
          <Button
            className="button rounded-5 button-secondary border-0"
            variant="secondary"
            onClick={cerrar}
          >
            Cerrar
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}

export default connect()(GestionFacturas);
